import Vue from "vue";

/*TITLE*/
document.title = "Edificio Cervantes | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "Edificio Cervantes";
Vue.prototype.$subtitle = "Atención directa y personalizada a todos los clientes. Por este motivo, ofrecemos la posibilidad de personalizar las viviendas durante la ejecución de las obras, con el objetivo de adecuarlas";

/*INTRO*/
Vue.prototype.$promoter = "Edificio Cervantes";
Vue.prototype.$introSubtitle = "Atención directa y personalizada a todos los clientes. Por este motivo, ofrecemos la posibilidad de personalizar las viviendas durante la ejecución de las obras, con el objetivo de adecuarlas";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "salon_opc-1_ac5-helvetia-1l-cervino_rgb--20230116090140.jpg";
Vue.prototype.$image_kitchen = "cocina_opc-6_bottega-aceromarmi-chinaarenaroble-negro_rgb--20230116090107.jpg";
Vue.prototype.$image_bath1 = "banyo-principal_opc3_miragedeco_rgb--20230116090153.jpg";
Vue.prototype.$image_bath2 = "banyo-secundario_opc-3_forest-natmarmimosaico-rodano_rgb--20230116090124.jpg";
Vue.prototype.$image_room = "";
Vue.prototype.$image_facade = "";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "salon_opc-1_ac5-helvetia-1l-cervino_rgb--20230116090140.jpg",
  },
  {
    src: "salon_opc-2_ac5-helvetia-1l-dom_rgb--20230116090148.jpg",
  },
  {
    src: "salon_opc-3_ac5-helvetia-1l-rigi_rgb--20230116090155.jpg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
  {
    src: "cocina_opc-6_bottega-aceromarmi-chinaarenaroble-negro_rgb--20230116090107.jpg",
  },
  {
    src: "cocina_opc-5_bottega-aceromarmi-chinaarenaroble-pardo_rgb--20230116090114.jpg",
  },
  {
    src: "cocina_opc-3_bottega-aceromarmi-chinaplomoroble-organico_rgb--20230116090121.jpg",
  },
  {
    src: "cocina_opc-3_bottega-aceromarmi-chinaarenaroble-arenado_rgb--20230116090128.jpg",
  },
  {
    src: "cocina_opc-2_bottega-aceromarmi-chinaplomobison_rgb--20230116090136.jpg",
  },
  {
    src: "cocina_opc-1_bottega-aceromarmi-chinaplomoblanco-nubol_jpg--20230116090143.jpg",
  },
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "banyo-principal_opc3_miragedeco_rgb--20230116090153.jpg",
  },
  {
    src: "previo-banyo-principal_opc2_marmi-chinamalagaforest-cognac_rgb--20230116090105.jpg",
  },
  {
    src: "banyo-principal_opc1_bottega-calizaforest-cognac_rgb--20230116090113.jpg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "banyo-secundario_opc-3_forest-natmarmimosaico-rodano_rgb--20230116090124.jpg",
  },
  {
    src: "banyo-secundario_opc-2_newport-aceroold_rgb--20230116090130.jpg",
  },
  {
    src: "banyo-secundario_opc-1_pradaforest-cognac_rgb--20230116090137.jpg",
  },
];

Vue.prototype.$gallery_images_room = [
];

Vue.prototype.$gallery_images_facade = [
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "3";
Vue.prototype.$gallery_grid_bath2 = "3";
Vue.prototype.$gallery_grid_room = "12";
Vue.prototype.$gallery_grid_facade = "12";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "";

/*3D FILM*/
Vue.prototype.$3dFilm = "https://www.youtube.com/embed/ltTF9JJlfvw";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = true;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=calle%20dean%20martí%20castellón%2012004",
    text: "Calle Dean Martí, Castellón, 12004",
  },
];

Vue.prototype.$showroom = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=san%20roque%20103%20a",
    text: "San Roque, 103-A",
  },
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:964255501",
    text: "964255501",
  },
  {
    icon: "mdi-email",
    link: "mailto:info@grupopardo.es",
    text: "info@grupopardo.es",
  },
];
